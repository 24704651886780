
/* 
//PODCAST GRID
.podcast-list{
    .podcast{
        position: relative;
    } 
    .sr_it-datetime, .sonaar-podcast-grid-categories{
        font-size: 1em;
        line-height: 1em;
        padding-bottom: 10px;
    }
    .sonaar-podcast-grid-categories{
        text-decoration: underline;
        text-transform: uppercase;
    }
    .post-tags{
        font-size: 0.7em;
        line-height: 0.8em;
    }
    .sr_it-meta{
        margin-bottom: 0;
        font-size: 1em;
        line-height: 1em;
    }
    .podcast-info time, .podcast-info p{
        color: inherit;
    }
    .sonaar-play-button{
        display: inline-block;
        padding: 10px 20px;
        margin-top: 10px;
        margin-right:13px;
        border-radius: 17px;
        background:#000;
        color:#fff;
        font-size: 15px;
        line-height: 15px;
        font-weight: bold;
        text-transform: uppercase;
        transition: 0.2s ease;
        &:before{
            content: "\f04b";
            font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Brands, Fontello;
            font-size: 14px;
            font-weight: 900;
            padding-right: 10px;
        }
    }

    .sonaar-podcast-length{
        margin-top: 10px;
        font-size: 14px;
        line-height: 14px;
        display: inline-block;

        &:before{
            content: "\f017";
            font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Brands, Fontello;
            font-weight: 400;
            padding-right: 8px;
        }
    }
    .sonaar-grid-bottom{
        position: absolute;
        bottom: 0px;
        margin-bottom: 30px;
    }
}

.sonaar-podcast-grid-img{
    display: block;
    width:100%;
}

.sonaar-podcast-grid-description{
    margin:15px 0px;
}
.podcast-info{
    padding: 20px;
    &.sonaar-display-play{
        padding-bottom: 76px;
    }

    h2{
        font-size: 24px!important;
        line-height: 28px!important;
    }

    .sr_it-meta .sr_it-meta-author-link{
        padding-right: 0;
    }

    a {
    font-size: 14px;
    line-height: 18px;
    }
}

.sonaar-podcast-grid-image{
    position: relative;
    display: block;
    min-height: 150px;
    i{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 60px;
        opacity: 0.5;
        transition: ease 0.5s;
    }
}
.sonaar-grid{
    &.sonaar-grid-2{
        grid-template-columns: 1fr 1fr ;
    }
    &.sonaar-grid-3{
        grid-template-columns: 1fr 1fr 1fr;
    }
    &.sonaar-grid-4{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
*/


/* PLAYER PODCAST */
.iron_podcast_player{

    .main-player-col{
        width: calc(100% - 170px);
        padding-left: 10px;
        padding-top:14px;
        display: inline-block;
        vertical-align: top;
    }

    background:#7bd8b4;
    padding: 6px;
    @media (min-width: 768px){
        padding: 22px;
        padding-bottom: 5px;
    }
    .currentTime{
        @media (max-width: 767px){
            display:none;
        }
    }
    .artwork-col{
        padding: 0px;
        padding-bottom: 16px;
        width: 138px;
        display: inline-block;
    }
    @media (max-width: 767px){
        .spectro{
            display:none;
        }
        .artwork-col{
            display: none;
        }
        .main-player-col{
            width:100%;
        }
    }
    @media (min-width: 768px){
    .no-image{
            .artwork-col{
                display: none;
            }
            .main-player-col{
                width:calc(100% - 26px);
            }
        }
    }
    
    .playlist-col, .buttons-col, .totalTime, .previous, .next{
        display: none;
    }


    .iron-audioplayer .track-title{
        text-align: left;
        font-size: inherit;
        line-height: inherit;
        
        @media (max-width: 767px){
            letter-spacing:0px!important;
            margin-bottom: 5px;
            float: left;
            width:calc(100% - 81px);
            font-size: 24px;
            line-height: 26px;
        }
    }
   .main-player-col .player{
        height: 45px;
        display: block;
        
        @media (max-width: 767px){
            display: inline-block;
            width: 50px;
          //  margin-top:5%;
        }
    }
    .wave{
        @media (min-width: 768px){
            display: inline-block;
            width: calc(100% - 100px);
        }
        display: none;
    }
    .control{
        @media (min-width: 768px){
            position: absolute;
            right: 0;
            top: 20%;
            transform: translate(0, -50%);
        }        
    }
    .play{
        height: 50px;
        width: 50px;
        padding: 11px 12px 10px 12px;
        box-sizing: border-box;
        border: 5px solid #fff;
        border-radius: 26px;

        @media (min-width: 768px){
            height: 68px;
            width: 68px;
            padding: 18px 19px 20px 19px;
            border-width: 6px;
            border-radius: 33px;
        }
    }
    .meta-podcast{
        font-size: 11px;
        line-height: 11px;
        text-transform: uppercase;
        display: inline-block;

        @media (min-width: 768px){
            font-size: 14px;
            line-height: 19px;
            vertical-align: bottom;
        }
        &>div{
            display: inline-block;
            margin-right: 10px;
            margin-bottom:5px;
            @media (min-width: 768px){
                margin-bottom:8px;
            }
        }
        .sonaar-category{
            font-weight: bold;
            text-decoration: underline;
        }
        .sonaar-duration{
            &:before{
                content: "\f017";
                font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Brands, Fontello;
                font-weight: 400;
                padding-right: 5px;
            }
        }
    }
    .sonaar-callToActions{
        display: inline-block;
    }
    .sonaar-callToAction{
        display: inline-block;
        margin-right: 10px;
        margin-bottom:5px;
        font-size: 11px;
        line-height: 11px;
        color: #7bd8b4;
        padding: 4px 15px;
        text-transform: uppercase;
        background: #19482f;
        border-radius: 15px; 
        @media (min-width: 768px){
            margin-bottom:8px;
        }
    }
    .sonaar-podcast-player-bottom{
        margin-bottom:20px;
        position: relative;
        top: 20px;
        @media only screen and (max-width: 767px) {
            margin-top:10px;
            
        }
    }
}


/* SINGLE PODCAST */
.has-player .sonaar-single-podcast, .single-podcast .has-player #sidebar{
    margin-top: 100px;
}

.single-podcast{
    .banner{
        .page-title{
            margin-top: 100px;
        }
        &.hide-title{
            .iron_podcast_player{
                transform: translate(0, -50%);
            }
            .sonaar-single-podcast, #sidebar{
                margin-top: 0px;
            }
        }
    }
}

/*PODCAST Sidebar*/

.single-podcast, .tax-podcast-category, .single-podcastshow{
    @media (min-width: 850px){
        .sidebar-right{
            float: left;
        }
        .sidebar-left{
            float: right;
        }
        .has-sidebar{
            width: 70%;
            padding-right: 25px;
            box-sizing: border-box;
        }
    } 
}

/*PODCAST CATEGORY*/
.tax-podcast-category{
    .podcast-category-container{
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .sonaar-taxonomy-description{
        margin: 60px 0;
    }
    .podcast-filter{
        display: none;
    }
    .podcast-category-container .banner{
        margin-top: -115px;
    }
    .page-title.left, .page-subtitle.left{
        padding-left: 15px;
    }
}

/* PODCAST FILTER*/
.podcast-filter{

    padding-left: 0;
    margin-bottom: 25px;
    li{
        transition: all 0.2s ease;
        font-size: 12px;
        list-style: none;
        display: inline-block;
        padding: 4px 16px;
        border-radius: 20px;
        cursor: pointer;
        
    }


}