.sr_it-event-buttons{
  right: 0;
  padding: 0 25px 0 12px;
  transition: 0.2s ease;
  .button{
    margin: 4px 0;
    &:last-of-type{
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    .fa{
      font-size:14px;
    }
  }
}

#sidebar{ 
  .sr_it-event-buttons{
    display: none;
  }
  .sr_it-event-main, .sr_it-event-info{
    max-width: 100% !important;
  }
  .sr_it-event-main{
    border-left-width: 1px !important;
  }
  .sr-it-event-date {
  padding-left: 12px !important;
  }
}

.sr_it-vertical-align{
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sr_it-event-buttons.sr_it-vertical-align{
  @media (max-width: 768px) {
  position: static;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  }
}
.concerts-list{
  padding-left: 0;
  border-top: 2px solid #000;
  text-align: left;

  *{
    box-sizing: initial !important;
  }
  .event{
    position: relative;
    border-bottom: 2px solid #000;
    transition: all 0.1s ease;
  }
  .event-list-countdown{
    display: none;
  }
  .nothing-found{
    padding: 50px;
  }
  
  [data-countdown="true"]{
    @media (min-width: 769px) {
      .event-list-countdown{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        padding: 0 5px;
        opacity: 0;
        transition: 0.2s ease;
      }
      .event-line-countdown-wrap{
        display: block;
        position: relative;
        left: 0;
        top: 50%;
      }
      .countdown-block {
        display: table;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box !important;
      }
      .event-link{
        &:hover{
          .sr-it-event-date{
            padding-left: 150px;
            opacity: 0;
          }
        }
      }
    }
  } 
}

.event-link{
  padding: 22px 0 16px 0;
  display: block;
  position: relative;
}

.sr-it-event-date{
  padding: 0 12px;
  transition: 0.2s ease;
  @media (min-width: 769px) {
    text-align: right;
    display: inline-table;
    vertical-align: top;
  }
  @media (max-width: 768px) {
    padding-left:12px !important;
  }
}

@media (max-width: 768px) {
  .sr-it-event-date{
    width:100% !important;
  }
  .sr-it-date-day,.sr-it-date-years {
    display:inline-block;
  }
  .sr-it-date-day{
    padding-right:6px;
  }
}

.sr-it-date-day, .sr_it-event-main h1{
    font-size: 32px;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 4px;
}

.sr-it-date-years, .sr_it-event-main{
  font-size: 14px;
  line-height: 22px;
}

.sr_it-event-main{
  padding: 0 12px;
  @media (min-width: 769px) {
    display: inline-block;
    border-left: 1px dotted #000;
  }
  h1{
    display: inline-block;
  }
}
.sr_it-event-info{
  max-width:825px;
}
.sr_it-event-info-button{
  padding-right: 15px;
  i{
    padding-left: 5px;
  }
}

span.events-bar-artists select{
  font-size: 18px;
  line-height: 18px;
}

.iron_widget_events{
  ul.pagination{
    margin-top: 20px;
  }
}
ul.pagination{
  list-style: none;
  padding: 0 20px;
  color: inherit;
  text-align: center;
  display: none;
  li{
    display:inline-block;
    color: inherit;
    &.active{
      font-weight: bold;
    }
    a{
      color: inherit;
    }
    + li:before{
      content:"-";
      padding: 0 10px;
    }
  }
}
.pagination-previous{
  float: left;
  &:before{
    content:"« ";
  }
  
}
.pagination-next{
  float: right;
  &:after{
    content:" »";
}
}
.event-pagination{
  &:after{
    content: "";
    display: table;
    clear: both;
  }
  button{
    @media (max-width: 425px) {
      min-width: 50px;
    }
    span{
      @media (max-width: 425px) {
        display: none;
      }
    }
    margin-top:30px;
  }
}

body:not(.page-template-archive-event) .event-pagination{
  display: none;
}