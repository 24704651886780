/*MENU SOCIAL NETWORK ICON*/
.menu-icon-applemusic>a:before, .menu-icon-bandcamp>a:before, .menu-icon-facebook>a:before, .menu-icon-googleplus>a:before, .menu-icon-googleplay>a:before, .menu-icon-instagram>a:before, .menu-icon-mixcloud>a:before, .menu-icon-snapchat>a:before, .menu-icon-soundcloud>a:before, .menu-icon-spotify>a:before, .menu-icon-twitter>a:before, .menu-icon-vimeo>a:before, .menu-icon-vk>a:before, .menu-icon-youtube>a:before{
    font-family: Font Awesome\ 5 Free, Font Awesome\ 5 Brands, Fontello;
    padding-right: 8px;
}
.menu-icon-applemusic>a:before{
    content: "\f179";
}
.menu-icon-bandcamp>a:before{
    content: "\f2d5";
}
.menu-icon-facebook>a:before{
    content: "\f39e";
}
.menu-icon-googleplay>a:before{
    content: "\f3ab";
}
.menu-icon-googleplus>a:before{
    content: "\f0d5";
}
.menu-icon-instagram>a:before{
    content: "\f16d";
}
.menu-icon-mixcloud>a:before{
    content: "\f289";
}
.menu-icon-snapchat>a:before{
    content: "\f2ab";
}
.menu-icon-soundcloud>a:before{
    content: "\f16d";
}
.menu-icon-spotify>a:before{
    content: "\f1bc";
}
.menu-icon-twitter>a:before{
    content: "\f099";
}
.menu-icon-vimeo>a:before{
    content: "\f27d";
}
.menu-icon-vk>a:before{
    content: "\f189";
}
.menu-icon-youtube>a:before{
    content: "\f167";
}
/* Class to rotate widgets and elements */
.rotateMe{
    transform: rotate(-90deg);
}
/* ESG Load More Button */
.esg-loadmore-wrapper {
    margin-top: 40px;
    margin-bottom: 60px;
}
/* GradientMaps effect
   ========================================================================== */
   .sr_it-gradientmaps-skin{
    overflow: hidden;
    .vc_single_image-wrapper{
       overflow: hidden;
    } 
    .esg-media-cover-wrapper:hover .esg-media-poster, .esg-media-cover-wrapper:hover .esg-entry-media{
	    filter:initial !important;
    }
}

/* Demo Custom CSS
   ========================================================================== */

/* MAGNETIC ELEMENTOR
   ========================================================================== */    
._magnetic_elementor {
    .sonaar-filters .esg-filterbutton, .sonaar-filters .esg-navigationbutton.esg-loadmore, .sonaar-filters .esg-sortbutton, .sonaar-filters .esg-cartbutton, .podcast-filter li, .esg-sortbutton, a.bt_rss_subscribe {
        color: inherit;
        background-color: inherit;
    }  
    
    /*Event list*/
        
    .events-bar {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    
    /*----------------Special menu-----------------*/
    
    @media (min-width: 1158px) {
        .elementor-section-stretched.elementor-section-full_width {
            padding-left: 68px!important;
        }
    
        .elementor-section.elementor-section-boxed>.elementor-container {
            max-width: 1000px;
        }
    
        .page-template-archive-posts-classic .container {
            max-width: 800px;
        }
    
        #pusher-wrap {
            left: 40px!important;
        }
                
        .side-menu:not(.open) .menu-main-menu-container,
        .side-menu:not(.open) .logo-desktop {
            opacity: 0;
        }
        .menu-main-menu-container,
        .logo-desktop {
            transition: 0.4s ease;
        }

        ul.sub-menu:not(.active) {
            overflow-y: hidden;
        }
    }

    @media (max-width: 1158px) {
        /*---make the menu icon black on mobile ---*/
        .menu-toggle span {
            background-color: #000!important;
        }
        .nav-menu li a {
            font-size: 28px;
            padding-bottom: 10px!important;
            margin-top: 0px!important;
        }
    }
    a.site-logo {
        top: 40px!important;
        position: absolute!important;
    }
    .side-menu {
        overflow-y: hidden;
    }
    .side-menu.open {
        overflow-y: auto;
    }
    .sub-menu.active li.menu-item a {
        font-size: 23px;
    }
    .side-menu.open .nav-menu li a {
        border-bottom: 1px solid #3d3d3d;
    }
    .nav-menu li a {
        padding-bottom: 23px;
    }
    .wp-admin-bar .site-title {
        margin-top: 84px;
    }
    .site-title {
        margin-top: 54px;
    }
    .nav-holder {
        top: 5vh;
    }
    .menu-toggle {
        padding: 20px;
        left: 0px!important;
    }
    .side-menu.lefttype:not(.open) {
        left: -300px;
    }
    .side-menu.lefttype.open {
            left: 0px;
    }
    .menu-toggle span {
        margin-top: 20px;
    }
    .sub-arrow i {
        display: none;
    }
    ul.header-top-menu li a {
        color: #000000!important;
    }
    
    /*----------------End Special menu-----------------*/
}


/* MAGNETIC WPBAKERY
   ========================================================================== */    
._magnetic {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    
     &.hide-header {   
        [data-vc-full-width="true"].vc_row {
        padding-left: initial!important;
        }   
     }
     
    a.promobox:hover img {
        filter: blur(3px) brightness(60%);
    }
    .sr_it-event-title,
    .sr_it-event-artist {
        font-weight: 600;
    }
    .events-bar-artists {
        margin-right: 30px;
    }
    .sr_it-event-buttons .button {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    #dropcap span.dropcap {
        margin-right: -4px;
    }
    /* button view all in the heading */
    
    .rowHeading {
        z-index: 1;
    }
    .rowHeading h2 {
        display: inline-block;
    }
    .rowHeading .vc_btn3-container {
        vertical-align: bottom;
        margin-top: 27px;
        float: right;
    }
    .rowHeading .vc_btn3.vc_btn3-style-outline-custom {
        border-width: 3px;
    }
    .vc_btn3.vc_btn3-size-lg {
        font-size: 24px;
        line-height: 32px;
    }
    @media (max-width: 800px) {
        .align-center-responsive>div>.wpb_wrapper>* {
            display: block !important;
            text-align: center !important;
            float: none !important;
        }
    }
    /*Event list*/
    
    .events-bar {
        padding-top: 35px;
        padding-bottom: 35px;
        padding-right: 0%;
    }

    /*----------------Special menu-----------------*/
    
    @media (min-width: 1158px) {
        [data-vc-full-width="true"].vc_row {
            padding-left: 68px!important;
        }

        #pusher-wrap {
            left: 40px!important;
        }
         
        .side-menu:not(.open) .menu-main-menu-container,
        .side-menu:not(.open) .logo-desktop {
            opacity: 0;
        }
        .menu-main-menu-container,
        .logo-desktop {
            transition: 0.4s ease;
        }

        ul.sub-menu:not(.active) {
            overflow-y: hidden;
        }

    }
    @media (max-width: 1158px) {
        /*---make the menu icon black on mobile ---*/
        .menu-toggle span {
            background-color: #000!important;
        }
        .nav-menu li a {
            font-size: 28px;
            padding-bottom: 10px!important;
            margin-top: 0px!important;
        }
    }
    a.site-logo {
        top: 40px!important;
        position: absolute!important;
    }
    .side-menu {
        overflow-y: hidden;
    }
    .side-menu.open {
        overflow-y: auto;
    }
    .sub-menu.active li.menu-item a {
        font-size: 23px;
    }
    .side-menu.open .nav-menu li a {
        border-bottom: 1px solid #3d3d3d;
    }
    .nav-menu li a {
        padding-bottom: 23px;
    }
    .wp-admin-bar .site-title {
        margin-top: 84px;
    }
    .site-title {
        margin-top: 54px;
    }
    .nav-holder {
        top: 5vh;
    }
    .menu-toggle {
        padding: 20px;
        left: 0px!important;
    }
    .side-menu.lefttype:not(.open) {
        left: -300px;
    }
    .side-menu.lefttype.open {
         left: 0px;
    }
    .menu-toggle span {
        margin-top: 20px;
    }
    .sub-arrow i {
        display: none;
    }
    ul.header-top-menu li a {
        color: #000000!important;
    }

    /*----------------End Special menu-----------------*/
}


/* CORNERSTONE ELEMENTOR
   ========================================================================== */    
._cornerstone_elementor {
    .sonaar-filters .esg-filterbutton, .sonaar-filters .esg-sortbutton{
        color: inherit;
    }
}


/* CORNERSTONE WPBAKERY
   ========================================================================== */    
._cornerstone {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    .single-artist h1 {
        font-size: 48px;
        line-height: 48px;
    }
    h1.sr_it-playlist-title {
        font-size: 24px;
        line-height: 25px;
    }
    &.home .playlist .sr_it-playlist-title,
    .sr_it-playlist-artists,
    .sr_it-playlist-release-date {
        display: none!important;
    }
    .woocommerce-checkout h3 {
        letter-spacing: 0;
    }
    @media (max-width: 800px) {
        .align-center-responsive>div>.wpb_wrapper>* {
            display: block !important;
            text-align: center !important;
            float: none !important;
        }
    }
    @media (max-width: 1189px) {  
        .cornerstone_2columns-format .vc_column-inner {
            margin-top: 30px!important;
            margin-left: 0px!important;
            margin-right: 0px!important;
        }
    }
    ul.header-top-menu.righttype li {
        margin: 7px 0 0 20px;
    }
    .classic-menu>ul>li.logo {
        overflow: initial;
    }
    .cornerstone button,
    .cornerstone .vc_btn3-container a {
        padding: 0px 0px 3px 0px!important;
        margin: 0px 10px 0px 10px!important;
        font-size: 36px!important;
        border-width: 0px 0px 4px 0px!important;
        font-weight: 600;
    }
    .cornerstone button:hover,
    .cornerstone .vc_btn3-container a:hover {
        margin-bottom: -7px!important;
        border-width: 0px 0px 7px 0px!important;
        border-color: inherit!important;
        padding: 0px 0px 7px 0px!important;
    }
    /*------------ 2 columns special widht format (home page -about column)----*/
    
    .cornerstone_2columns-format>.wpb_column:first-of-type p {
        line-height: 31px;
        letter-spacing: 4px;
        font-size: 12px;
        margin-bottom: 30px
    }
    .cornerstone_home_singleimage {
        z-index: -1;
    }
    .cornerstone_homepage_grid.vc_row {
        background-position: left!important;
    }
    /* button view all in the heading */
    
    @media (max-width: 755px) {
        .rowHeading .vc_custom_heading,
        .rowHeading {
            text-align: center !important;
        }
    }
    @media (min-width: 756px) {
        .rowHeading {
            z-index: 1;
        }
        .rowHeading h2 {
            display: inline-block;
        }
        .rowHeading .vc_btn3-container {
            vertical-align: bottom;
            margin-top: 42px;
            float: right;
        }
    }
    .sr_it-grid {
        z-index: -1;
    }
    &.page-template-default h1.page-title {
        font-size: 64px;
        line-height: 54px;
        font-weight: 700;
    }
    /* added to main style */
    
    figure {
        padding: 0px;
    }
    /* added to main style ! */
    
    .events-bar {
        border-top-color: rgba(255, 255, 255, 0)!important;
        margin-bottom: 40px;
    }
    span.events-bar-title {
        /* added to main style ! */
        
        text-transform: initial;
    }
    @media (max-width: 768px) {
        .cornerstone_2columns-format>.wpb_column:first-of-type>.vc_column-inner {
            width: initial;
            margin-left: 25px !important;
            margin-right: 25px !important
        }
    }
    /*tmp*/
    
    &.page-template-archive-posts-list .media-block .text-box {
        position: absolute;
    }
    @media (max-width: 1290px) {
        .classic-menu>ul>li a {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
        
   
}

/* APEX ELEMENTOR
   ========================================================================== */    
._apex_elementor {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    #sonaar-player .close.btn-player {
        background-color: #fff;
    }
    .iron-audioplayer .track-number svg {
        top: 1px;
    }
    #sonaar-player .player {
        border-color: initial;
    }
    .sr_it-text{
        color: #fff;
    }
   
}

/* APEX WPBakery
   ========================================================================== */    
._apex {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    #sonaar-player .close.btn-player {
        background-color: #fff;
    }
    .iron-audioplayer .track-number svg {
        top: 1px;
    }
    #sonaar-player .player {
        border-color: initial;
    }
    .sr_it-text{
        color: #fff;
    }
    @media (max-width: 768px) {
        .wpb_video_widget.vc_video-el-width-70 .wpb_wrapper {
            width: 100%
        }
    }
    /*MAILCHIMP FORM*/
    
    .mc4wp-form input[type="text"],
    .mc4wp-form input[type="email"],
    .mc4wp-form input[type="tel"],
    .mc4wp-form input[type="url"],
    .mc4wp-form input[type="date"],
    .mc4wp-form input[type="number"],
    .mc4wp-form select,
    .mc4wp-form textarea {
        background: transparent;
        border-radius: 0;
        padding: 18px 27px;
        box-sizing: border-box;
        border-width: 2px;
    }
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        background: transparent;
    }
    .mc4wp-form {
        text-align: left;
    }
    .mc4wp-form button,
    .mc4wp-form input[type=submit] {
        margin-left: 0;
        padding: 20px 40px;
        border-radius: 0;
    }
    input[type="email"]::-webkit-input-placeholder {
        color: #fff !important;
        opacity: 1;
        font-weight: 400 !important;
        font-size: 21px !important;
        font-family: Josefin Sans;
    }
    input[type="email"]:-moz-placeholder {
        color: #fff !important;
        opacity: 1;
        font-weight: 400 !important;
        font-size: 21px !important;
        font-family: Josefin Sans;
    }
    input[type="email"]:-ms-input-placeholder {
        color: #fff !important;
        opacity: 1;
        font-weight: 400 !important;
        font-size: 21px !important;
        font-family: Josefin Sans;
    }
    .mc4wp-form input[type=text],
    .mc4wp-form input[type=email],
    .mc4wp-form input[type=tel],
    .mc4wp-form input[type=url],
    .mc4wp-form input[type=date],
    .mc4wp-form input[type=number],
    .mc4wp-form select,
    .mc4wp-form textarea {
        font-size: 18px;
        color: #fff;
        font-family: Josefin Sans;
        line-height: 30px;
    }
    
    /*footer*/
    @media screen and (max-width: 767px){
        #footer{
            .vc_btn3-shape-round.vc_btn3-size-lg {
                width: 52px;
                height: 52px;
                transform: translate(50%, 0);
                margin-bottom: 20px;
            }
            .vc_btn3-container:first-child {
                margin-left: 30px !important;
            }
            .vc_btn3-container:last-child{
                margin-right: 30px !important;
            }
        }
    }
   
}
/* KRONOXX
   ========================================================================== */    
._kronoxx {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    .splitColumns p{
        color:#000;
    }

    .vc_row.textoverlay{
        background-position-x: center!important;
        background-position-y: top!important;
        background-size: inherit!important;
    }
    .iron-audioplayer .album .album-art {
        max-width: 570px;
    }

    @media (max-width:1024px){
    .splitColumns .wpb_column:first-child{
        padding-top:100px!important;
        padding-bottom:100px!important;
        padding:10%;
    }
    }
    @media (max-width:1920px){
    .vc_row.textoverlay{
        background-size: contain!important;
    }
        
    }
    .sr_it-playlist-title {
        font-weight: 700;
    }
    .sr_it-playlist-artists, .sr_it-playlist-release-date {
        font-style: inherit;
    }
    .defaultdivider {
        height: 2px;
        width: 40px;
    }
    .nav-menu li a.backbtn {
        background: none;
        font-size: 14px;
    }
    
    .social-networks a i {
        font-size: 21px;
    }
    
    
    /*MAILCHIMP FORM*/
    .mc4wp-form input[type="text"], .mc4wp-form input[type="email"], .mc4wp-form input[type="tel"], .mc4wp-form input[type="url"], .mc4wp-form input[type="date"], .mc4wp-form input[type="number"], .mc4wp-form select, .mc4wp-form textarea {
    background: transparent;
    border-radius: 0;
    padding: 18px 27px;
    box-sizing: border-box;
    border-width: 2px;
    }
    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
         background: transparent;   
    }
    .mc4wp-form {
        text-align: left;
    }
    .mc4wp-form button, .mc4wp-form input[type=submit]{
        margin-left: 0;
        padding: 20px 40px;
        border-radius: 0;
    }
    input[type="email"]::-webkit-input-placeholder{
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    input[type="email"]:-moz-placeholder { 
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    input[type="email"]:-ms-input-placeholder { 
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form input[type=number], .mc4wp-form select, .mc4wp-form textarea{
    line-height: 30px;
    }    
   
}
/* MILEND for Elementor


   ========================================================================== */    
._milend_elementor {
    .classic-menu.mini .logo img {
        height: 45px;
    }
    .classic-menu:not(.responsive) #menu-main-menu > li:not(.logo) > a {
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 20px;
        margin-left: 20px;
    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a {
        padding-bottom: 10px;
        border-bottom: 6px solid rgba(0,0,0,0);

    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a:hover {
        border-bottom: 6px solid;
    }
    /* Avoid content box to overlap the album cover */
    &.single-album .album-header {
        margin-bottom: 100px;
    }
    /* Remove background because we want to see the background image */
    &.single-album .sr_it-single-post.featured, .single-album #sidebar {
        background: none!important;
    }
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    .small-icon-bt .elementor-button-icon {
        font-size: 0.5em;
        line-height: 1.8em;
    }

    .sonaar-elementor-inline-block .elementor-widget-container{ 
        display: inline-block; 
    }

    .sr-dropshadow.elementor-section-stretched, .sr-dropshadow .album-art img {
        box-shadow: 0 10px 20px 3px rgba(0,0,0,0.35);
    }
    .media-block a, .articles-classic .media-block .holder, .esg-grid .mainul li.eg-news-skin-split-wrapper {
        background-color: rgba(45,75,76,0.42)!important;
    }
}

/* MILEND
   ========================================================================== */    
._milend {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    #overlay {
        filter: brightness(50%);
    }
    
    // WooCommerce
    [role="listbox"] li{
        color: #000;
    }
    
    .widget-title{
        font-family: 'Poppins';
        letter-spacing: 2px!important;
    }
    .sr-letter-spacing-6{
        letter-spacing: 6px;
    }
    .sr-letter-spacing-2{
        letter-spacing: 2px;
    }
    
    /*search box*/
    .search-form input[type="search"]{
        border-color: #fff;
        background: none;
        color: #fff;
        min-width:200px;
        width: calc(100% - 135px);
    }
    .search-form input[type="search"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #fff;
    }
    .search-form input[type="search"]::-moz-placeholder { /* Firefox 19+ */
        color: #fff;
    }
    .search-form input[type="search"]:-ms-input-placeholder { /* IE 10+ */
        color: #fff;
    }
    .search-form input[type="search"]:-moz-placeholder { /* Firefox 18- */
        color: #fff;
    }
    .widget_search input[type="submit"]{
        margin: 0px 10px 0px 10px!important;
        font-size: 14px!important;
        background-color: rgba(255,255,255,0)!important;
        border-width: 2px!important;
        border: solid!important;
    }
    
    /*Event*/
    #artists_filter option {
        color: rgb(38, 36, 35);
    }
    
    
    /*button*/
    button{
        font-family: inherit;
    }
    .sr_it-event-buttons .button {
    border-radius: 23px;
    }
    .style1-button .vc_btn3.vc_btn3-size-lg{
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 3px;
        font-weight: 400;
    }
    .style1-button .vc_btn3-size-lg.vc_btn3-style-outline-custom{
        padding: 17px 65px;
        border-radius: 30px;
    }
    figure{
        padding-top: 0;
    }
    
    
    /*homepage post grid*/
    .color1_txt>div, .vc_grid p{
        color: inherit !important;
    }
    .vc_grid .vc_custom_heading a{
        text-decoration: underline;
    }
    
    
    /*form*/
    .light-form, .light-form p{
        color: #fff;
    }
    .light-form textarea{
        background: none !important;
    }
    .light-form input, .light-form textarea{
        background: none;
        border: 1px solid #fff;
        color: #fff;
    }
    input[type="submit"]{
        background: none;
        border-width: 2px;
        border: solid;
        padding: 17px 65px;
        height: initial;
        border-radius: 30px;
        letter-spacing: 3px;
        margin: 0px;
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        transition: all .2s ease-in-out;
    }
    .light-form input[type="submit"]{
        margin-top: 30px;
    }
}
/* MANDALA
   ========================================================================== */    
._mandala {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    /*HOME social media*/
    .vc_icon_element-align-center{
        display: inline-block;
        }
    .vc_icon_element-align-center+.vc_icon_element-align-center{
        margin-left:30px;
    }
    
    /*Contact page*/
    #dropcap span.dropcap {
        margin-right: 5px;
        margin-top: 19px;
    }
}


/* DOWNBEAT WPBakery
   ========================================================================== */    
._downbeat {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    .vc_row.textoverlay{
        background-position-x: center!important;
        background-position-y: top!important;
        background-size: inherit!important;
    }
    
    @media (max-width:1920px){
    .vc_row.textoverlay{
        background-size: contain!important;
    }
        
    }    
    #overlay {
    filter: brightness(0.5);
    }
    
    /*MAILCHIMP FORM*/
    .mc4wp-form input[type="text"], .mc4wp-form input[type="email"], .mc4wp-form input[type="tel"], .mc4wp-form input[type="url"], .mc4wp-form input[type="date"], .mc4wp-form input[type="number"], .mc4wp-form select, .mc4wp-form textarea {
    background: transparent;
    border-radius: 0;
    padding: 18px 27px;
    box-sizing: border-box;
    border-width: 2px;
    }
    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
         background: transparent;   
    }

    .mc4wp-form button, .mc4wp-form input[type=submit]{
        margin-left: 0;
        padding: 20px 40px;
        border-radius: 0;
    }
    input[type="email"]::-webkit-input-placeholder{
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    input[type="email"]:-moz-placeholder { 
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    input[type="email"]:-ms-input-placeholder { 
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form input[type=number], .mc4wp-form select, .mc4wp-form textarea{
    line-height: 30px;
    }   
   
}  
/* MOONLIGHT ELEMENTOR
   ========================================================================== */    
._moonlight_elementor {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    .classic-menu:not(.responsive) #menu-main-menu > li:not(.logo) > a {
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 20px;
        margin-left: 20px;
    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a {
        padding-bottom: 10px;
        border-bottom: 6px solid rgba(0,0,0,0);

    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a:hover {
        border-bottom: 6px solid;
    }
    /* Avoid content box to overlap the album cover */
    &.single-album .album-header {
        margin-bottom: 100px;
    }
    /* Remove background because we want to see the background image */
    &.single-album .sr_it-single-post.featured, .single-album #sidebar {
        background: none!important;
    }  
}  
/* MOONLIGHT WPBakery
   ========================================================================== */    
._moonlight {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    .classic-menu:not(.responsive) #menu-main-menu > li:not(.logo) > a {
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 20px;
        margin-left: 20px;
    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a {
        padding-bottom: 10px;
        border-bottom: 6px solid rgba(0,0,0,0);

    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a:hover {
        border-bottom: 6px solid;
    }
    /* Avoid content box to overlap the album cover */
    &.single-album .album-header {
        margin-bottom: 100px;
    }
    /* Remove background because we want to see the background image */
    &.single-album .sr_it-single-post.featured, .single-album #sidebar {
        background: none!important;
    }
    .vc_row.textoverlay{
        background-position-x: center!important;
        background-position-y: top!important;
        background-size: inherit!important;
    }
    
    @media (max-width:1920px){
        .vc_row.textoverlay{
            background-size: contain!important;
        }   
    }    

    
    /*MAILCHIMP FORM*/
    .mc4wp-form input[type="text"], .mc4wp-form input[type="email"], .mc4wp-form input[type="tel"], .mc4wp-form input[type="url"], .mc4wp-form input[type="date"], .mc4wp-form input[type="number"], .mc4wp-form select, .mc4wp-form textarea {
    background: transparent;
    border-radius: 0;
    padding: 18px 27px;
    box-sizing: border-box;
    border-width: 2px;
    }
    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
         background: transparent;   
    }

    .mc4wp-form button, .mc4wp-form input[type=submit]{
        margin-left: 0;
        padding: 20px 40px;
        border-radius: 0;
    }
    input[type="email"]::-webkit-input-placeholder{
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    input[type="email"]:-moz-placeholder { 
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    input[type="email"]:-ms-input-placeholder { 
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form input[type=number], .mc4wp-form select, .mc4wp-form textarea{
    line-height: 30px;
    }   
   
}


/* PRODIGY Elementor
   ========================================================================== */ 



/* PRODIGY WPBakery
   ========================================================================== */  
._prodigy {

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    h1.sr_it-videolist-item-title{
    letter-spacing:0px;
    }
    .sub-arrow i {
        padding-top: 9px;
        font-size: 16px;
    }
    .promo-overlay {
        background: none;
    }
    .sr_it-playlist-title {
        font-weight: 700;
    }
    .sr_it-playlist-artists, .sr_it-playlist-release-date {
        font-style: inherit;
    }
    .defaultdivider {
        height: 2px;
        width: 40px;
    }
    .nav-menu li a.backbtn {
        background: none;
        font-size: 14px;
    }
    .social-networks a i {
        font-size: 21px;
    }
    
    
    /*MAILCHIMP FORM*/
    .mc4wp-form input[type="text"], .mc4wp-form input[type="email"], .mc4wp-form input[type="tel"], .mc4wp-form input[type="url"], .mc4wp-form input[type="date"], .mc4wp-form input[type="number"], .mc4wp-form select, .mc4wp-form textarea {
    background: transparent;
    border-radius: 0;
    padding: 18px 27px;
    box-sizing: border-box;
    border-width: 2px;
    }
    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
         background: transparent;   
    }
    
    .mc4wp-form button, .mc4wp-form input[type=submit]{
        margin-left: 0;
        padding: 20px 40px;
        border-radius: 0;
    }
    input[type="email"]::-webkit-input-placeholder{
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    input[type="email"]:-moz-placeholder { 
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    input[type="email"]:-ms-input-placeholder { 
    color: #fff !important;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 21px !important;
    font-family: quicksand;
    }
    .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form input[type=number], .mc4wp-form select, .mc4wp-form textarea{
    line-height: 30px;
    }
}

/* INDIGO ELEMENTOR
   ========================================================================== */    

._indigo_elementor{

    /* border-bottom under the main menu */
    .classic-menu #menu-main-menu > li:not(.logo) > a {
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 40px;
    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a {
        padding-bottom: 10px;
        border-bottom: 6px solid rgba(0,0,0,0);
    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a:hover {
        border-bottom: 6px solid;
    }
  
}
/* INDIGO
   ========================================================================== */    

._indigo{

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    /* border-bottom under the main menu */
    .classic-menu #menu-main-menu > li:not(.logo) > a {
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 40px;
    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a {
        padding-bottom: 10px;
        border-bottom: 6px solid rgba(0,0,0,0);

    }
    .classic-menu:not(.mini):not(.responsive) #menu-main-menu > li:not(.logo) > a:hover {
        border-bottom: 6px solid;
    }

    /*Button custom style*/
    .indigo-button-style button, .indigo-button-style a{
        font-weight: bold;
    }
    .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form select, .mc4wp-form textarea {
      max-width: 290px;
    }
}
/* Beats for Elementor
   ========================================================================== */   
._beats_elementor{
    .sr-inlineblock .elementor-widget-container{
        display: inline-block;
    }
    @media (min-width: 992px){
        .iron-audioplayer.show-playlist .buttons-block {
            padding-left: calc(25% - 185px);
        }
    }
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .esg-grid {
        font-family: inherit;
    }
    sup {
        font-size: 22px;
    }
}
/* Beat$
   ========================================================================== */   
._beats{

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .esg-grid {
        font-family: inherit;
    }
    sup {
        font-size: 22px;
    }
    #wrapper .vc_btn3.vc_btn3-size-lg {
        font-weight: 700;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }
    #footer .footercredit p{
        font-size:13px;
        letter-spacing:2px;
    }
    .mc4wp-form button, .mc4wp-form input[type=submit], .mc4wp-form input[type=button] {
        font-weight: 700;
        border-radius:65px;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }
}


/* Soundman Elementor
   ========================================================================== */  
._soundman_elementor{
    /*Playlist featured image ratio 2:3*/
    @media only screen and (min-width: 1025px){
        .albumCover {
            width: 464px;
            height: 696px;
        }
    }
    .album-art{
        overflow: hidden;
    }

    &.single-album .sr_it-single-post.featured:not(.no-banner){
        margin-top: 0;
    }

    &.single-album .sr_it-single-post.featured, .single-album #sidebar {
        background: none !important;
    }
}    
     

/* Soundman WPBakery
   ========================================================================== */   
._soundman{

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    .vc_icon_element-align-center{
        display: inline-block;
        }
    .vc_icon_element-align-center+.vc_icon_element-align-center{
        margin-left:30px;
    }
    .entry p{
        letter-spacing: 2px;
    }
    .vc_general.vc_btn3{
        letter-spacing: 6px;
        padding: 12px 44px;
    }
    .sr_it-nav, .sr_it-artist-nav a{
        color: #fff;
    }

    /*Playlist featured image ratio 2:3*/
    @media only screen and (min-width: 1025px) {
        .albumCover {
            width: 464px;
            height: 696px;
        }
    }
    .album-art{
        overflow: hidden;
    }
    /* Remove background because we want to see the background image */
    &.single-album .sr_it-single-post.featured, .single-album #sidebar {
        background: none!important;
    }
}
/* The Crew Elementor
   ========================================================================== */   
._thecrew_elementor{
    // for heading big strong block style on homepage add .mainheading class on the heading.
    .mainheading .elementor-widget-heading .elementor-widget-container{
        display: inline-block;
    }
    .classic-menu.incontainer:not(.responsive) .menu-container, .classic-menu:not(.responsive).incontainer:not(.responsive) .menu-container{
        padding:0px!important;
    }
    .classic-menu:not(.responsive).mini .logo {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    .container, .single .container, .blog .container, .page-template .container {
        padding-left: 40px!important;
        padding-right: 40px!important;
    }

    #page-banner.fullscreen-banner, .album-header {
    /* since we're using a boxed layout, we want a more banner-style look */
        height: 70vh;
    }

    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .sr_it-nav, .sr_it-artist-nav a{
    color: #fff;
    }
    .esg-grid {
        font-family: inherit;
    }
    sup {
        font-size: 22px;
    }
    @media (max-width:768px){
        .container{
            padding-left:30px!important;
            padding-right:30px!important;
        }
        &.single-album .container{
            padding-left:0!important;
            padding-right:0!important;
        }
    }
    @media (max-width:1200px){
        #overlay {
            background-image: none;
        }
    }
}
/* The Crew WPBakery
   ========================================================================== */   
._thecrew{

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    .container, .single .container, .blog .container, .page-template .container {
        padding-left: 40px!important;
        padding-right: 40px!important;
    }

    #page-banner.fullscreen-banner, .album-header {
    /* since we're using a boxed layout, we want a more banner-style look */
        height: 70vh;
    }

    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .sr_it-nav, .sr_it-artist-nav a{
    color: #fff;
    }
    .esg-grid {
        font-family: inherit;
    }
    sup {
        font-size: 22px;
    }
    #wrapper .vc_btn3.vc_btn3-size-lg {
        font-weight: 700;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }
    #footer .footercredit p{
        font-size:13px;
        letter-spacing:2px;
    }
    .mc4wp-form {
        margin: auto;
        width: 310px!important;
    }
    .mc4wp-form button, .mc4wp-form input[type=submit], .mc4wp-form input[type=button] {
        font-weight: 700;
        color: #000!important;
        text-transform: uppercase;
        font-family: inherit;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }
    .concerts-list{
        border-bottom: 1px dashed; 
        .event {
            border-bottom: 1px dashed;
        }
    }

    @media (max-width:768px){
        .container{
            padding-left:30px!important;
            padding-right:30px!important;
        }
        &.single-album .container{
            padding-left:0!important;
            padding-right:0!important;
        }
        div .vc_custom_heading.sr_it-inline-block {
            font-size: 38px!important;
            line-height: 46px!important;
        }
    }
    @media (max-width:1200px){
        #overlay {
            background-image: none;
        }
    }
}

/* Skullz Elementor
   ========================================================================== */ 
._skullz_elementor{
    .woocommerce-page .woocommerce-info{
        color: #000000;
    }
}

/* Skullz WPBakery
   ========================================================================== */ 
._skullz{

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    .page-title {
        text-align: center;
    }
    .page-title h1, .error404 h1 {
        display: inline-block;
        border-bottom: 10px solid #dedede;
        padding-bottom: 4px;
    }
    .promotext h1, .promotext h2, .promotext h3, .promotext h4, .promotext h5, .promotext h6 {
        line-height: 2.25vw;
    }
    a.promobox:hover .promosubtext {
        margin-top: 10px;
    }

    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .sr_it-nav, .sr_it-artist-nav a{
    color: #fff;
    }
    .esg-grid {
        font-family: inherit;
    }
    #wrapper .vc_btn3.vc_btn3-size-lg {
        font-weight: 700;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }
    #footer .footercredit p{
        font-size:13px;
        letter-spacing:2px;
    }
    .mc4wp-form {
        margin: auto;
        width: 310px!important;
    }
    .mc4wp-form button, .mc4wp-form input[type=submit], .mc4wp-form input[type=button] {
        font-weight: 700;
        color: #000!important;
        text-transform: uppercase;
        font-family: inherit;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }


    @media (max-width:768px){
       /* .container{
            padding-left:30px;
            padding-right:30px;
        }*/
        div .vc_custom_heading.sr_it-inline-block {
            font-size: 38px!important;
            line-height: 46px!important;
        }
    }
    @media (max-width:1200px){
        #overlay {
            background-image: none;
        }
    }
    @media (max-width: 1400px){
        .promotext h1, .promotext h2, .promotext h3, .promotext h4, .promotext h5, .promotext h6 {
            line-height: 2.8rem;
        }
    }
}


/* Studio Elementor
========================================================================== */ 
._studio_elementor{
    .elementor-widget-sonaar-mailchimp input{
        text-align: center;
    }
}  

/* Studio WPBakery
========================================================================== */   
._studio{

    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }

    /*button*/
    .vc_btn3.vc_btn3-size-md.vc_btn3-shape-round.vc_btn3-style-custom{
        font-weight: 600;
    }
    @media (min-width: 769px) {
        .vc_btn3.vc_btn3-size-md.vc_btn3-shape-round.vc_btn3-style-custom{
            font-size: 21px;
            line-height: 21px;
            padding: 25px 70px;
        }
    }
    /*promobox*/
    .promotext h1, .promotext h2, .promotext h3, .promotext h4, .promotext h5, .promotext h6 {
        line-height: 2.25vw;
    }
    @media only screen and (max-width: 767px){
        .promotext h1, .promotext h2, .promotext h3, .promotext h4, .promotext h5, .promotext h6 {
            line-height: 8vw;
        }
    }
    
    .special-big-text p{
        font-size: 21px;
        line-height: 36px;
        font-weight: 600;
    }
    
    .special-medium-text p{
        font-size: 18px;
        line-height: 28px;
    }
    
    
    /*footer*/
    #footer .container {
        max-width: 1400px;
    }
    #footer form{
    text-align: inherit;
    }
    #footer .vc_btn3.vc_btn3-size-lg .vc_btn3-icon {
        font-size: 34px;
        height: 34px;
        line-height: 34px;
    }
    
    .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form input[type=number], .mc4wp-form select, .mc4wp-form textarea {
        color: #dfd9df;
        background: transparent;
        text-align: center;
        font-size: 14px;
        line-height: 18px;
        padding:  0 15px;
        height: 48px;
        box-sizing: border-box;
        border: 2px solid;
        border-radius: 32px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
    
    #footer ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #dfd9df !important;
    opacity: 1 !important;
    }
    #footer ::-moz-placeholder { /* Firefox 19+ */
      color: #dfd9df !important;
    opacity: 1 !important;
    }
    #footer :-ms-input-placeholder { /* IE 10+ */
      color: #dfd9df !important;
    opacity: 1 !important;
    }
    #footer :-moz-placeholder { /* Firefox 18- */
      color: #dfd9df !important;
    opacity: 1 !important;
    }
    
    .mc4wp-form input[type=submit]{
        font-size: 14px;
        line-height: 18px;
        height: 50px;
        text-transform: uppercase;
        font-weight: 500;
        padding:  0 15px;
        border-radius: 32px;
        letter-spacing:1px;
        margin-top: 0;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
        color: #dfd9df !important;
        box-sizing: content-box;
    }
    
    #footer .mc4wp-form input[type=submit]:hover{
        color: #dfd9df !important;
        background-color: #179a7d !important;  
    }
    
    @media (min-width: 768px) {
        .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form input[type=number], .mc4wp-form select, .mc4wp-form textarea {
            font-size: 21px;
            line-height: 21px;
            height: 58px;
            width: calc(65% - 70px);
            max-width: 435px;
            box-sizing: content-box;
            margin-left: 0;
            margin-right:8%;
        }
        .mc4wp-form input[type=submit]{
            padding: 0 15px;
            height: 60px;
            width: 25%;
            font-size: 17px;
            line-height: 21px;
            margin-left: 0;
            margin-right: 0;
        }
    }
    
    .footercredit{
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
    .footercredit a{
        text-decoration: underline;
    }
    
}

/* Kimchee Elementor
   ========================================================================== */   
._kimchee_elementor{
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    .social-networks a i {
        font-size: 21px;
    }
    .single-album .buttons-block .available-now {
        font-weight: initial;
    }
    .single-album .content-box .blockquote-block figcaption {
        font-weight: initial;
    }
    .info-section .links-block {
        border-top: 1px solid #000;
    }
    .classic-menu.responsive .logo img {
        height: 70px;
    }
}


/* Kimchee
   ========================================================================== */   
._kimchee{
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    
    @media (min-width:850px){
        .site-logo img {
            width: 150px;
        }
    }
}

/* Karbones Elementor
   ========================================================================== */  
._karbones_elementor{

    /*MENU HOVER UNDERLINE EFFECT*/
    .classic-menu>ul>li:not(.hotlink) a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu>ul>li:not(.hotlink) a:hover .menuItemUnderline, .classic-menu ul>li.current-menu-item:not(.hotlink)>a>.menuItemUnderline{
        right: 0;
    }
    .menuItemUnderline{
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        background: #fff;
        height: 8px;
        -webkit-transition-property: right;
        transition-property: right;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .responsive .menuItemUnderline{
        display: none;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 8px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }
}

/* Karbones
   ========================================================================== */  
._karbones{

    //menu
    @media (max-width: 1529px){
        .classic-menu:not(.responsive) .classic-menu-hot-links{
        display: none;
        }
        .classic-menu:not(.responsive) .logo{
        padding-left: 0;
        }
    }
    .classic-menu.responsive #menu-main-menu > li.current-menu-item > a, .classic-menu.responsive > ul > li a:hover, .classic-menu.responsive > ul > li.languages-selector > ul > li a:hover, .classic-menu.responsive > ul > li.current-menu-ancestor > a  {
        color: #494949;
    }
    .classic-menu.responsive #menu-main-menu .sub-menu a:hover{
        background: transparent;
    }

     /*MENU HOVER UNDERLINE EFFECT*/
     .classic-menu>ul>li:not(.hotlink) a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu>ul>li:not(.hotlink) a:hover .menuItemUnderline, .classic-menu ul>li.current-menu-item:not(.hotlink)>a>.menuItemUnderline{
        right: 0;
    }
    .menuItemUnderline{
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        background: #fff;
        height: 8px;
        -webkit-transition-property: right;
        transition-property: right;
        -webkit-transition-duration: 0.2s;
        transition-duration: 0.2s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .responsive .menuItemUnderline{
        display: none;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 8px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }

    //Slider
    .tp-caption b, .tp-caption a{
        font-size: inherit !important;
        line-height: inherit !important;
        letter-spacing: inherit !important;
    }
    .rev-btn.rev-withicon i{
        font-size: inherit;
        line-height: inherit;
        padding-right:10px;
        margin-left: 0 !important;
    }

    //button color
    .comment-form #submit, .wpcf7-submit, a.button:hover, a.button, .mc4wp-form button, .mc4wp-form input[type=submit]{
        color: #000;
    }
    .button{
        text-transform: uppercase;
    }
        
    //Fullscreen Window    
    @media only screen and (min-width: 800px){
        #wrapper, #wrapper>.container{
            min-height: 100vh;
        }
            .admin-bar #wrapper, .admin-bar #wrapper>.container{
            min-height: calc(100vh - 32px);
        }
    }
            
}
/* Outlander Elementor
   ========================================================================== */  
._outlander_elementor{
    //video-list
    .sr_it-videolist-item-title{
        letter-spacing: 0;
    }
    //eventslist
    .sr_it-event-main h1{
    text-transform: uppercase;
    }
    .sr_it-event-buttons {
    text-transform: uppercase;
    font-weight: 600;
    }
    .sr_it-event-buttons i{
    display: none;
    }
    .concerts-list .event{
    border: 1px solid;
    margin-bottom: 8px;
    }
    .concerts-list{
    border: 0;
    }
    body .sr-it-event-date .sr-it-date-years{
    color: #fff;
    font-weight: 400;
    }
    body .sr_it-event-main{
    border-color: #fff;
    }
    .concerts-list .event:hover {
       border-color: transparent;
    }
   
    //Woocommmerce
    #add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment { 
        background: rgba(255, 255, 255, 0.1);
    }

}
/* Outlander WPBakery
   ========================================================================== */  
._outlander{

    //Remove logo on desktopo only
    .classic-menu:not(.responsive) .classic-menu-logo{
        display: none;
        }
        .classic-menu:not(.responsive)  .logo{
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    //Bold Style
    .vc_custom_heading b{
        font-weight: inherit !important;
        display: inline-block;
    }

    a.button{
        color: #000;
    }

    .overflowVisible{
        overflow: visible !important;
    }

    //video-list
    .sr_it-videolist-item-title{
        letter-spacing: 0;
    }
    
    //eventslist
    .sr_it-event-main h1{
    text-transform: uppercase;
    }
    .sr_it-event-buttons {
    text-transform: uppercase;
    font-weight: 600;
    }
    .sr_it-event-buttons i{
    display: none;
    }
    .concerts-list .event{
    border: 1px solid;
    margin-bottom: 8px;
    }
    .concerts-list{
    border: 0;
    }
    body .sr-it-event-date .sr-it-date-years{
    color: #fff;
    font-weight: 400;
    }
    body .sr_it-event-main{
    border-color: #fff;
    }
    .concerts-list .event:hover {
       border-color: transparent;
    }
     
    //Footer
    .outlander-socialnetwork a{
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 21px !important;
        letter-spacing: 1px !important;
        background-image: unset !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        margin-left: 25px !important;
        margin-right: 25px !important;
    }
    .outlander-socialnetwork i{
        font-size: 19px !important;
        position: static !important;
        display: inline !important;
        padding-right: 11px !important;
    }
    
    //Woocommmerce
    #add_payment_method #payment, .woocommerce-cart #payment, .woocommerce-checkout #payment { 
        background: rgba(255, 255, 255, 0.1);
    }

}
/* Podcastr Elementor
   ========================================================================== */   
._podcastr_elementor{

    .sr_it-videolist-item-title {
        font-size: 18px;
        line-height: 20px;
        letter-spacing:0px; 
    }
    .sr_it-videolist-item-date {
        font-size: 14px;
        line-height: 14px;
    }
    h1.page-title {
        font-size: 42px;
        line-height: 42px;
    }
    .page-subtitle {
        font-size: 21px;
        line-height: 24px;
    }
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .esg-grid {
        font-family: inherit;
    }
    sup {
        font-size: 22px;
    }
    
    /* PLAYER */
    .iron-audioplayer .track-title {
    font-weight: bold;
    }
    .iron_widget_radio.iron_podcast_player {
        box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
    }
    .iron_podcast_player .iron-audioplayer .track-title {
        letter-spacing: -1px;
    }
    #sonaar-player .playerNowPlaying .metadata {
        letter-spacing: 0px;
    }



     /* MENU */
    .classic-menu ul.sub-menu li a {
        letter-spacing: 0px;
    }
    
    .classic-menu:not(.mini):not(.responsive) .menu-container{
        margin-top: 33px;
    }
    
    .classic-menu.responsive #menu-main-menu .current-menu-item > a, .classic-menu.responsive a:hover, .classic-menu.responsive .classic-menu-hot-links > li.languages-selector > ul > li a:hover, .classic-menu.responsive ul > li.current-menu-ancestor > a  {
        color: #494949;
    }
    .classic-menu.responsive .sub-menu li a:hover{
        background: transparent;
    }

    /*MENU HOVER UNDERLINE EFFECT*/
    .classic-menu #menu-main-menu a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 5px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }

}
/* Podcastr WPBakery
   ========================================================================== */   
._podcastr{


    /* PODCAST SHOW SINGLE PAGE */
    .host-avatar{
        display: inline-block;
    }
    .host-name{
        position: relative;
        top: 10px;
        margin-left: 10px;
    }

   
    .sr_it-videolist-item-title {
        font-size: 18px;
        line-height: 20px;
        letter-spacing:0px; 
    }
    .sr_it-videolist-item-date {
        font-size: 14px;
        line-height: 14px;
    }
    h1.page-title {
        font-size: 42px;
        line-height: 42px;
    }
    .page-subtitle {
        font-size: 21px;
        line-height: 24px;
    }
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .esg-grid {
        font-family: inherit;
    }
    sup {
        font-size: 22px;
    }
    #wrapper .vc_btn3.vc_btn3-size-lg {
        //font-weight: 700;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }

    /* PLAYER */
    .iron-audioplayer .track-title {
    font-weight: bold;
    }
    .iron_widget_radio.iron_podcast_player {
        box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
    }
    .iron_podcast_player .iron-audioplayer .track-title {
        letter-spacing: -1px;
    }
    #sonaar-player .playerNowPlaying .metadata {
        letter-spacing: 0px;
    }



    /* BUTTONS */
    .vc_btn3 {
        padding: 15px 30px;
    }
    .vc_btn3:not(:hover) {
        background-color:#000!important;
    }


     /* MENU */
    .classic-menu ul.sub-menu li a {
        letter-spacing: 0px;
    }
    
    .classic-menu:not(.mini):not(.responsive) .menu-container{
        margin-top: 33px;
    }
    
    .classic-menu.responsive #menu-main-menu .current-menu-item > a, .classic-menu.responsive a:hover, .classic-menu.responsive .classic-menu-hot-links > li.languages-selector > ul > li a:hover, .classic-menu.responsive ul > li.current-menu-ancestor > a  {
        color: #494949;
    }
    .classic-menu.responsive .sub-menu li a:hover{
        background: transparent;
    }

    /*MENU HOVER UNDERLINE EFFECT*/
    .classic-menu #menu-main-menu a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 5px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }


    /* FOOTER */


    #footer .footercredit p{
        font-size:13px;
        letter-spacing:2px;
    }
    .mc4wp-form button, .mc4wp-form input[type=submit], .mc4wp-form input[type=button] {
        //font-weight: 700;
        border-radius:65px;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }
}

/* Castopress Elementor
   ========================================================================== */   
._castopress_elementor{

    /*SIDEBAR*/
    #sidebar .panel__heading h3, .elementor-widget-sidebar .panel__heading h3 { color: #fff;}
    .terms-list a { color: inherit; }

    @media screen and (min-width: 1161px) { 
        .albumCover{
            margin-top: 110px;
        }
        &.single-album .sr_it-single-post.featured {
            margin-top: -50px;
        }
    }   

    /* MENU */
    .classic-menu ul.sub-menu li a {
        letter-spacing: 0px;
    }
    .classic-menu.responsive > ul > li.current-menu-item > a, .classic-menu.responsive > ul > li a:hover, .classic-menu.responsive > ul > li.languages-selector > ul > li a:hover, .classic-menu.responsive > ul > li.current-menu-ancestor > a  {
        color: #494949;
    }
    .classic-menu.responsive > ul > li > ul > li a:hover{
        background: transparent;
    }

     /*MENU HOVER UNDERLINE EFFECT*/
     .classic-menu #menu-main-menu a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 5px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }
}

/* Castopress WPBakery
   ========================================================================== */   
._castopress{

    h1, h2, h3, h4, h5, h6 {
        font-weight:initial;
    }
    .about-text .vc_custom_heading strong {
        color:#bb825d;
        font-weight:400;
    }
    .about-text p{
        line-height:28px;
        text-transform:uppercase;
    }
    .sponsor-stats p{
        text-transform:uppercase;
    }
    .sponsorkit-stats-numbers p, .sponsorkit-stats-numbers h1{
        color:rgb(34, 35, 37);   
    }
    .sponsorkit-stats-numbers .wpb_column .vc_column-inner{
        padding: 70px 50px!important;
        border-radius: 11px;
        background-color:#fff!important;
    }
    @media only screen and (max-width: 849px){
        .container {
            padding-top: 30px;
        }
        #footer .container {
            padding-top: 0px;
        }
    }

    .terms-list a {
        color: inherit!important;
    }
    blockquote {
        border-left-color: inherit;
    }
    /*Background color of the podcast show single banner*/
    .single-podcastshow .vc_row.wpb_row.vc_row.vc_row-has-fill {
        background-color: #19191b!important;
    }

    /* PODCAST SHOW SINGLE PAGE */
    .host-avatar{
        display: inline-block;
    }
    .host-name{
        position: relative;
        top: 10px;
        margin-left: 10px;
    }
    .sr_it-videolist-item-title {
        font-size: 18px;
        line-height: 20px;
       letter-spacing:0px; 
    }
    .sr_it-videolist-item-date {
        font-size: 14px;
        line-height: 14px;
    }
    h1.page-title {
        font-size: 42px;
        line-height: 42px;
    }
    .page-subtitle {
        font-size: 21px;
        line-height: 24px;
    }
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .esg-grid {
        font-family: inherit;
    }
    sup {
        font-size: 22px;
    }
    #wrapper .vc_btn3.vc_btn3-size-lg {
        //font-weight: 700;
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }

    /* PLAYER */
    .iron-audioplayer .track-title {
        text-transform:uppercase;
    }
    .iron_widget_radio.iron_podcast_player {
        box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
        border-radius: 6px;
    }
    #sonaar-player .playerNowPlaying .metadata {
        letter-spacing: 0px;
    }


    /* BUTTONS */
    .vc_btn3 {
        padding: 15px 30px;
    }
    .vc_btn3:not(:hover) {
        color:inherit!important;
        background-color:#19191b!important;
    }


     /* MENU */
    .classic-menu ul.sub-menu li a {
        letter-spacing: 0px;
    }
    .classic-menu.responsive > ul > li.current-menu-item > a, .classic-menu.responsive > ul > li a:hover, .classic-menu.responsive > ul > li.languages-selector > ul > li a:hover, .classic-menu.responsive > ul > li.current-menu-ancestor > a  {
        color: #494949;
    }
    .classic-menu.responsive > ul > li > ul > li a:hover{
        background: transparent;
    }

     /*MENU HOVER UNDERLINE EFFECT*/
     .classic-menu #menu-main-menu a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 5px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }

    /* FOOTER */
    #footer .footercredit p{
        font-size:13px;
        letter-spacing:2px;
    }
    #footer p{
        text-transform:uppercase;
        color:inherit;
    }
    #footer .vc_btn3.vc_btn3-style-custom{
        background-image:none!important;
    }
    .mc4wp-form button, .mc4wp-form input[type=submit], .mc4wp-form input[type=button] {
        padding: 11px 27px;
        border-radius:30px;
        color: #19191b!important;
        font-family: inherit!important;
        text-transform: uppercase;
    }
    .mc4wp-form input {
        font-family: inherit;
        text-transform: uppercase;
    }

    .mc4wp-form input::placeholder {
        color: #7d8189!important;
    }
    .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form input[type=number], .mc4wp-form select, .mc4wp-form textarea {
        color:#7d8189;   
        text-transform: uppercase;
        border-radius: 30px!important;
        background-color: #3e4249;
        border: none;
    }
}

/* PROMOTE Elementor
   ========================================================================== */   
._promote_elementor {
    .rotateMeCW {
        transform: rotate(90deg);
    }
    .classic-menu:not(.responsive).incontainer:not(.responsive).classic_menu_align-pull-center.classic_menu_logo_align-pull-left .logo, .classic-menu:not(.responsive).fullwidth:not(.responsive).classic_menu_align-pull-center.classic_menu_logo_align-pull-left .logo {
         left: 0px;
    }
    .logo-desktop, .classic-menu.mini .logo img {
        height: 67px;
    }
    .paypal-donate{
        text-align:center;
    }
    .paypal_btn {
        font-size: 18px;
        letter-spacing: 1px;
        padding: 15px 30px;
        border-radius: 30px 30px 30px 30px!important;
        transition-timing-function: cubic-bezier(.16,.28,.42,1.05);
        transition: all 300ms ease-in-out;
        text-transform: uppercase;
        color: #fff;
        background-color: #941dea;
        border: 0;
        box-shadow: 0px 0px 20px 1px #941dea;
    }
    .paypal_btn:hover {
        transform: scale(1.1);
        background-color: #511781;
        box-shadow: 0px 0px 10px 1px #130523;
    }
    .events-bar {
        margin-bottom: 15px;
    }
    @media (max-width:1280px){
        .verticalHeadingLeft {
            top:50%!important;
            left: -195px!important;
        }
        .verticalHeadingRight {
            top:50%!important;
            right: -180px!important;
        }
    }
    /*Ess Grid Filter*/
    @media (min-width: 920px){
        .sonaar-filters .eg-search-wrapper{
            float: right;
            margin-top:30px;
        }
        .sonaar-filters .esg-filters:after {
            content: "";
            display: table;
            clear: both;
        }
    }
}

/* STREAMKING Elementor
   ========================================================================== */   
._streamking_elementor{
    .paypal-donate{
       text-align:center;
    }
    .paypal_btn {
        font-size: 18px;
        letter-spacing: 1px;
        padding: 15px 30px;
        border-radius: 30px 30px 30px 30px!important;
        transition-timing-function: cubic-bezier(.16,.28,.42,1.05);
        transition: all 300ms ease-in-out;
        text-transform: uppercase;
        color: #fff;
        background-color: #941dea;
        border: 0;
        box-shadow: 0px 0px 20px 1px #941dea;
    }
    .paypal_btn:hover {
        transform: scale(1.1);
        background-color: #511781;
        box-shadow: 0px 0px 10px 1px #130523;
    }
    .classic-menu.mini {
        box-shadow: 0px 0px 10px #0000002e;
    }
    /*MENU HOVER UNDERLINE EFFECT*/
     .classic-menu #menu-main-menu a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 5px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }
}

/* Speakerpress Elementor
   ========================================================================== */   
._speakerpress_elementor{

     /*MENU HOVER UNDERLINE EFFECT*/
     .classic-menu #menu-main-menu a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 5px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }
    
}


/* Speakerpress WPBakery
   ========================================================================== */   
._speakerpress{

 h1, h2, h3, h4, h5, h6 {
        font-weight:initial;
    }
    .about-text .vc_custom_heading strong {
        color:#61DAC3;
        font-weight:400;
    }
    .about-text p{
        line-height:28px;
        text-transform:uppercase;
    }
    .sponsor-stats p{
        text-transform:uppercase;
    }
    .sponsorkit-stats-numbers p, .sponsorkit-stats-numbers h1{
        color:#fff;   
    }
    .sponsorkit-stats-numbers .wpb_column .vc_column-inner{
        padding: 70px 50px!important;
        border-radius: 11px;
        background-color:#61DAC3!important;
    }
    @media only screen and (max-width: 849px){
        body:not(.home) .container {
            padding-top: 30px;
        }
        #footer .container {
            padding-top: 0px;
        }
    }

    .terms-list a {
        color: inherit!important;
    }
    blockquote {
        border-left-color: inherit;
    }
    /*Background color of the podcast show single banner*/
    .single-podcastshow .vc_row.wpb_row.vc_row.vc_row-has-fill {
        background-color: #19191b!important;
    }

    /* PODCAST SHOW SINGLE PAGE */
    .host-avatar{
        display: inline-block;
    }
    .host-name{
        position: relative;
        top: 10px;
        margin-left: 10px;
    }
    .sr_it-videolist-item-title {
        font-size: 18px;
        line-height: 20px;
       letter-spacing:0px; 
    }
    .sr_it-videolist-item-date {
        font-size: 14px;
        line-height: 14px;
    }
    h1.page-title {
        font-size: 42px;
        line-height: 42px;
    }
    .page-subtitle {
        font-size: 21px;
        line-height: 24px;
    }
    .eg-sonaar-events-grid01-element-14-a{
        min-height: 40px;
    }
    span.heading-b3 {
        height: 3px!important;
        width: 45px!important;
    }
    .esg-grid {
        font-family: inherit;
    }
    sup {
        font-size: 22px;
    }
    #wrapper .vc_btn3.vc_btn3-size-lg {
        padding-left: 65px!important;
        padding-right: 65px!important;
        font-size: 24px!important;
    }

    /* PLAYER */
    .iron-audioplayer .track-title {
        text-transform:uppercase;
    }
    .iron_widget_radio.iron_podcast_player {
        box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
        border-radius: 6px;
    }
    #sonaar-player .playerNowPlaying .metadata {
        letter-spacing: 0px;
    }


    /* BUTTONS */
    .vc_btn3 {
        padding: 15px 30px;
    }
    .vc_btn3:not(:hover) {
        color:inherit!important;
        background-color:#dddddd!important;
    }


     /* MENU */
    .classic-menu #menu-main-menu ul.sub-menu li a {
        letter-spacing: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu.responsive > ul > li.current-menu-item > a, .classic-menu.responsive > ul > li a:hover, .classic-menu.responsive > ul > li.languages-selector > ul > li a:hover, .classic-menu.responsive > ul > li.current-menu-ancestor > a  {
        color: #494949;
    }
    .classic-menu.responsive > ul > li > ul > li a:hover{
        background: transparent;
    }

     /*MENU HOVER UNDERLINE EFFECT*/
     .classic-menu #menu-main-menu a{
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
    }
    .classic-menu #menu-main-menu .sub-menu a{
        padding-left: 10px;
        padding-right: 10px;
    }
    .classic-menu #menu-main-menu a:hover:after, .classic-menu #menu-main-menu > li.current-menu-item > a:after{
        right: 0;
    }
    .classic-menu #menu-main-menu a:after{
        content:'';
        position: absolute;
        left: 0;
        right: 100%;
        bottom: 0;
        height: 5px;
        transition-property: right;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
    }
    .responsive #menu-main-menu a:after{
        display: none;
    }
    .classic-menu #menu-main-menu .logo a:after{
        display: none;
    }

    /* FOOTER */
    #footer .footercredit p{
        font-size:13px;
        letter-spacing:2px;
    }
    #footer p{
        text-transform:uppercase;
        color:inherit;
    }
    #footer .vc_btn3.vc_btn3-style-custom{
        background-image:none!important;
    }
    #footer .vc_general.vc_btn3:focus, #footer .vc_general.vc_btn3:hover {
       color: #595d63!important;
   }
    .mc4wp-form button, .mc4wp-form input[type=submit], .mc4wp-form input[type=button] {
        padding: 11px 27px;
        border-radius:30px;
        color: #fff!important;
        font-family: inherit!important;
        text-transform: uppercase;
    }
    .mc4wp-form input {
        font-family: inherit;
        text-transform: uppercase;
    }

    .mc4wp-form input::placeholder {
        color: #7d8189!important;
    }
    .mc4wp-form input[type=text], .mc4wp-form input[type=email], .mc4wp-form input[type=tel], .mc4wp-form input[type=url], .mc4wp-form input[type=date], .mc4wp-form input[type=number], .mc4wp-form select, .mc4wp-form textarea {
        color:#7d8189;   
        text-transform: uppercase;
        border-radius: 30px!important;
        background-color: #ececec;
        border: none;
    }
}


/* Symphony Elementor
   ========================================================================== */ 
._symphony_elementor{
    .sonaar-podcast-player-bottom {
        display: none!important;
    }
}


/* SPECIAL CLASS
========================================================================== */ 

/*sonaar-elementor-inline-block--> Can bu used to add a border bottom to a elementor heading*/
.sonaar-elementor-inline-block .elementor-widget-container{
    display: inline-block;
}