h1.page-title {
    font-size: 84px;
    line-height: 75px;
    margin-bottom: 40px;
}
.page-subtitle {
    font-size: 36px;
    line-height: 32px;
}
#page-banner {
  z-index:0;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

}

#page-banner .page-banner-bg {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: relative;
  top: 0%;
  background-size: cover!important;
  opacity: 1;
  overflow: visible;
  will-change: transform, opacity;
  transform:rotateX(0deg) translate(0px, 0px) translateZ(0);

}

#page-banner .page-banner-content {

  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  margin: 0;
  position: absolute;
  z-index: 300;
  display: table;
  will-change: transform, opacity;
  transform: rotateX(0deg) translate(0px, 0px) translateZ(0);
}

.page-banner-row{
  max-width: 1480px;
  margin: 0 auto;
}

#page-banner .page-banner-content .inner {
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  display: table-cell;
  padding: 0 26px;
}

#page-banner .page-banner-content .inner.top {vertical-align: top;}
#page-banner .page-banner-content .inner.middle {vertical-align: middle;}
#page-banner .page-banner-content .inner.bottom {vertical-align: bottom;}


#page-banner .page-banner-content .inner h1 {padding-top:0px !important;    word-break: break-word;}

#page-banner.fullscreen-banner{
    height: 100vh;
}

.page-banner-content .page-title, .page-banner-content .page-subtitle{
  transform:rotateX(0deg) translate(0px, 0px) translateZ(0);
}

#page-banner .page-banner-content .page-title.left {
  text-align: left;
}
#page-banner .page-banner-content .page-title.centered {
  text-align: center;
}
#page-banner .page-banner-content .page-title.right {
  text-align: right;
}
#page-banner .page-banner-content .page-subtitle {
  color: #fff;
  display: block;
}
#page-banner .page-banner-content .page-subtitle.left {
  text-align: left;
}
#page-banner .page-banner-content .page-subtitle.centered {
  text-align: center;
}
#page-banner .page-banner-content .page-subtitle.right {
  text-align: right;
}
@media only screen and (max-width: 1920px) {
  .page-banner-row{
    max-width: 1144px;
  }
}

