.single-album {
    span.heading-b3{
        margin-bottom:0px;
    }
    .sr_it-single-post.featured{
        @media (max-width: 768px) {
            padding: 80px 0px 0;
        }
    }
    .sr_it-single-post{

        &.no-banner{
            margin-top:0px;
            padding-top:0px;
        }
    }
    .sr_it-singlealbum-content-wrapper{
        padding: 0px 50px 0px 50px;
        margin: 50px 0px 50px 0px;
        @media (max-width: 768px) {
            padding: 10px;
        }
    }
    .iron-audioplayer{
        .playlist{
            max-width: 600px;
            margin: auto;
            padding: 5px 25px 25px 25px;
        }
    }
}


.album-template.album-template-single-album-sidecover {
    .sr_it-singlealbum-content-wrapper{
        @media (min-width: 1159px) {
            padding: 50px;
        }
    }
     .content-box, .sr_it-single-post.featured{
        background:none;
    }
    .sr_it-single-post.album:not(.no-banner) {
        @media (min-width: 1159px) {
            margin-top: 120px;
        }
        padding: 75px 30px 75px 30px;
        @media (max-width: 1159px) {
            padding: 75px 0px 75px 0px;
        }
    }
    .heading-b3{
        display:none;
    }
    .iron-audioplayer .playlist ul {
        margin-top: 40px;
    }
    .sr_it-single-post.no-banner {
        padding-top: 0px;
        margin-top: 0px;
        @media (max-width: 768px) {
                padding-top: 0px;
                margin-top: 0px;
        }
    }
    
    .playlist {
        padding: 0px;
        @media (max-width: 991px) {
                margin-top:20px;
                padding: 0px; 
        }
    }

    .title-album-enabled{ 
        .sr_it-playlist-title, .sr_it-playlist-artists { 
            display: none !important;
        }
    }

}